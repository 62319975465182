import isNotAuth from '@/router/middleware/isNotAuth'
import auth from '@/router/middleware/auth'
import notFound from '@/router/middleware/notFound'
import checkPermissions from '@/router/middleware/checkPermissions'

import { ROUTES } from '@/constants/routes'
import { PERMISSION_NAMES } from '@/constants/permissions'

export const BASE_ROUTES = [
    {
        path: '/auth',
        name: ROUTES.auth,
        redirect: { name: ROUTES.login },
        component: () => import('@/views/auth/Auth.vue'),
        children: [{
            path: 'login',
            name: ROUTES.login,
            meta: {
                layout: 'empty', middleware: isNotAuth, title: 'Dialics Admin Login', reference: ROUTES.auth,
            },
            component: () => import('@/views/auth/auth-login/AuthLogin.vue'),
        }],
    }, {
        path: '/users-list',
        name: ROUTES.usersList,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.usersList,
            permission: PERMISSION_NAMES.seeRegularUsers,
        },
        component: () => import('@/views/users/Users.vue'),
    }, {
        path: '/users-list/:id',
        name: ROUTES.userDetail,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.usersList,
            permission: PERMISSION_NAMES.seeRegularUsers,
        },
        component: () => import('@/views/users/DetailUser.vue'),
    }, {
        path: '/user-reporting',
        name: ROUTES.userReporting,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.userReporting,
            permission: PERMISSION_NAMES.seeRegularUsers,
        },
        component: () => import('@/views/user-reporting/UserReporting.vue'),
    }, {
        path: '/accounts',
        name: ROUTES.manageAccounts,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.manageAccounts,
            permission: PERMISSION_NAMES.seeAccounts,
        },
        component: () => import('@/views/accounts/Accounts.vue'),
    }, {
        path: '/accounts/:slug',
        name: ROUTES.detailAccount,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.manageAccounts,
            permission: PERMISSION_NAMES.seeAccounts,
        },
        component: () => import('@/views/accounts/DetailAccount.vue'),
    }, {
        path: '/account-report',
        name: ROUTES.accountReport,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.accountReport,
            permission: PERMISSION_NAMES.seeAccounts,
        },
        component: () => import('@/views/account-report/AccountReport.vue'),
    }, {
        path: '/payment-cards',
        name: ROUTES.managePaymentCards,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.managePaymentCards,
            permission: PERMISSION_NAMES.seeAccounts,
        },
        component: () => import('@/views/payment-cards/PaymentCard.vue'),
    }, {
        path: '/:account/payment-cards/:id',
        name: ROUTES.detailPaymentCards,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.managePaymentCards,
            permission: PERMISSION_NAMES.seeAccounts,
        },
        component: () => import('@/views/payment-cards/DetailPaymentCard.vue'),
    }, {
        path: '/voice-platforms',
        name: ROUTES.manageVoicePlatforms,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.manageVoicePlatforms,
            permission: PERMISSION_NAMES.seeVoicePlatformApplications,
        },
        component: () => import('@/views/voice-platforms/VoicePlatforms.vue'),
    }, {
        path: '/voice-platforms/:id',
        name: ROUTES.detailVoicePlatforms,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.manageVoicePlatforms,
            permission: PERMISSION_NAMES.updateVoicePlatformApplications,
        },
        component: () => import('@/views/voice-platforms/DetailVoicePlatform.vue'),
    }, {
        path: '/call-record-restore',
        name: ROUTES.callRecordRestore,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.callRecordRestore,
            permission: PERMISSION_NAMES.listenToRecords,
        },
        component: () => import('@/views/call-record-restore/CallRecordRestore.vue'),
    }, {
        path: '/roles',
        name: ROUTES.roles,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.roles,
            permission: PERMISSION_NAMES.seeRoles,
        },
        component: () => import('@/views/roles/Roles.vue'),
    }, {
        path: '/roles/:id',
        name: ROUTES.detailRole,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.roles,
            permission: PERMISSION_NAMES.seeRoles,
        },
        component: () => import('@/views/roles/DetailRoles.vue'),
    }, {
        path: '/number-permissions',
        name: ROUTES.numberPermissions,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.numberPermissions,
            permission: PERMISSION_NAMES.seePhoneNumberPermissions,
        },
        component: () => import('@/views/number-permissions/NumberPermissions.vue'),
    }, {
        path: '/account-rates',
        name: ROUTES.accountRates,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.accountRates,
            permission: PERMISSION_NAMES.seeRates,
        },
        component: () => import('@/views/account-rates/AccountRates.vue'),
    }, {
        path: '/account-rates/:id',
        name: ROUTES.detailAccountRate,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.accountRates,
            permission: PERMISSION_NAMES.seeRates,
        },
        component: () => import('@/views/account-rates/AccountRateDetail.vue'),
    }, {
        path: '/country-rates',
        name: ROUTES.countryRates,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.countryRates,
            permission: PERMISSION_NAMES.seeVoicePlatformCountryRates,
        },
        component: () => import('@/views/country-rates/CountryRates.vue'),
    }, {
        path: '/country-rates/:id',
        name: ROUTES.detailCountryRate,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.countryRates,
            permission: PERMISSION_NAMES.seeVoicePlatformCountryRates,
        },
        component: () => import('@/views/country-rates/CountryRateDetail.vue'),
    }, {
        path: '/payments-history',
        name: ROUTES.paymentsHistory,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.paymentsHistory,
            permission: PERMISSION_NAMES.seePayments,
        },
        component: () => import('@/views/payments-history/PaymentsHistory.vue'),
    }, {
        path: '/payments-history/:id',
        name: ROUTES.detailPaymentHistory,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.paymentsHistory,
            permission: PERMISSION_NAMES.seePayments,
        },
        component: () => import('@/views/payments-history/DetailPaymentHistory.vue'),
    }, {
        path: '/incoming-carrier',
        name: ROUTES.incomingCarrier,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.incomingCarrier,
            permission: PERMISSION_NAMES.seeIncomingCarriers,
        },
        component: () => import('@/views/incoming-carrier/IncomingCarrier.vue'),
    }, {
        path: '/incoming-carrier/:id',
        name: ROUTES.detailIncomingCarrier,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.incomingCarrier,
            permission: PERMISSION_NAMES.seeIncomingCarriers,
        },
        component: () => import('@/views/incoming-carrier/DetailIncomingCarrier.vue'),
    }, {
        path: '/byoc',
        name: ROUTES.byoc,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.byoc,
            permission: PERMISSION_NAMES.seeIncomingCarriers,
        },
        component: () => import('@/views/byoc/Byoc.vue'),
    }, {
        path: '/bank-of-numbers',
        name: ROUTES.bankOfNumbers,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.bankOfNumbers,
            permission: PERMISSION_NAMES.seeIncomingCarriers,
        },
        component: () => import('@/views/bank-of-numbers/BankOfNumbers.vue'),
    }, {
        path: '/bank-of-numbers/:id',
        name: ROUTES.bankOfNumberDetail,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.bankOfNumberDetail,
            permission: PERMISSION_NAMES.seeIncomingCarriers,
        },
        component: () => import('@/views/bank-of-numbers/DetailBankOfNumbers.vue'),
    }, {
        path: '/admin-users',
        name: ROUTES.adminUsers,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.adminUsers,
            permission: PERMISSION_NAMES.seeUsers,
        },
        component: () => import('@/views/admin-users/AdminUsers.vue'),
    }, {
        path: '/admin-users/:id',
        name: ROUTES.adminUserDetail,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.adminUsers,
            permission: PERMISSION_NAMES.seeUsers,
        },
        component: () => import('@/views/admin-users/DetailAdminUser.vue'),
    }, {
        path: '/numbers',
        name: ROUTES.numbers,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.numbers,
            permission: PERMISSION_NAMES.seePhoneNumbers,
        },
        component: () => import('@/views/numbers/Numbers.vue'),
    }, {
        path: '/numbers-fees',
        name: ROUTES.numberFees,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.numberFees,
            permission: PERMISSION_NAMES.seePhoneNumbers,
        },
        component: () => import('@/views/number-fees/NumberFees.vue'),
    }, {
        path: '/activity-logs',
        name: ROUTES.activityLogs,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.activityLogs,
            permission: PERMISSION_NAMES.seeActivityLogs,
        },
        component: () => import('@/views/activity-logs/ActivityLogs.vue'),
    }, {
        path: '/activity-logs/:id',
        name: ROUTES.detailActivityLog,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.activityLogs,
            permission: PERMISSION_NAMES.seeActivityLogs,
        },
        component: () => import('@/views/activity-logs/DetailActivityLog.vue'),
    }, {
        path: '/number-pools',
        name: ROUTES.numberPool,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.numberPool,
            permission: PERMISSION_NAMES.seeNumberPool,
        },
        component: () => import('@/views/number-pools/NumberPools.vue'),
    }, {
        path: '/affiliates',
        name: ROUTES.affiliates,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.affiliates,
            permission: PERMISSION_NAMES.seeAffiliateUsers,
        },
        component: () => import('@/views/affiliates/Affiliates.vue'),
    }, {
        path: '/affiliates/:id',
        name: ROUTES.detailAffiliates,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.affiliates,
            permission: PERMISSION_NAMES.seeAffiliateUsers,
        },
        component: () => import('@/views/affiliates/DetailAffiliates.vue'),
    }, {
        path: '/withdrawal-requests',
        name: ROUTES.withdrawalRequests,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.withdrawalRequests,
            permission: PERMISSION_NAMES.seeAffiliateWithdrawals,
        },
        component: () => import('@/views/withdrawal-requests/WithdrawalRequests.vue'),
    }, {
        path: '/withdrawal-requests/:slug',
        name: ROUTES.detailWithdrawalRequests,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.withdrawalRequests,
            permission: PERMISSION_NAMES.seeAffiliateWithdrawals,
        },
        component: () => import('@/views/withdrawal-requests/DetailWithdrawalRequests.vue'),
    }, {
        path: '/affiliate-referrals',
        name: ROUTES.affiliateReferrals,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.affiliateReferrals,
            permission: PERMISSION_NAMES.seeAffiliateReferrals,
        },
        component: () => import('@/views/affiliate-referrals/AffiliateReferrals.vue'),
    },
    /* {
        path: '/number-pools/:slug',
        name: ROUTES.detailNumberPool,
        meta: { layout: 'main', middleware: auth, reference: ROUTES.detailNumberPool },
        component: () => import('@/views/number-pools/DetailNumberPools.vue'),
    }, */
    {
        path: '/dashboard',
        name: ROUTES.dashboard,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions],
            reference: ROUTES.dashboard,
            permission: PERMISSION_NAMES.seeDashboard,
        },
        component: () => import('@/views/dashboard/Dashboard.vue'),
    }, {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: { layout: 'empty', middleware: notFound },
        component: () => import('@/views/NotFound.vue'),
    },
]
