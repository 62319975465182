export const ROUTES = {
    auth: 'Auth',
    login: 'AuthLogin',
    accountRates: 'AccountRates',
    detailAccountRate: 'DetailAccountRate',
    countryRates: 'CountryRates',
    detailCountryRate: 'DetailCountryRate',
    usersList: 'UsersList',
    userDetail: 'UserDetail',
    userReporting: 'UserReporting',
    paymentsHistory: 'PaymentsHistory',
    detailPaymentHistory: 'DetailPaymentHistory',
    numbers: 'Numbers',
    numberFees: 'NumberFees',
    numberPermissions: 'NumberPermissions',
    roles: 'Roles',
    detailRole: 'DetailRole',
    incomingCarrier: 'IncomingCarrier',
    detailIncomingCarrier: 'DetailIncomingCarrier',
    byoc: 'Byoc',
    bankOfNumbers: 'BankOfNumbers',
    bankOfNumberDetail: 'BankOfNumberDetail',
    manageAccounts: 'ManageAccounts',
    detailAccount: 'DetailAccount',
    managePaymentCards: 'managePaymentCards',
    detailPaymentCards: 'detailPaymentCard',
    manageVoicePlatforms: 'ManageVoicePlatforms',
    detailVoicePlatforms: 'DetailVoicePlatforms',
    callRecordRestore: 'CallRecordRestore',
    adminUsers: 'AdminUsers',
    adminUserDetail: 'AdminUserDetail',
    activityLogs: 'ActivityLogs',
    detailActivityLog: 'DetailActivityLog',
    numberPool: 'NumberPool',
    detailNumberPool: 'DetailNumberPool',
    accountReport: 'AccountReport',
    dashboard: 'Dashboard',
    affiliates: 'Affiliates',
    detailAffiliates: 'DetailAffiliates',
    withdrawalRequests: 'WithdrawalRequests',
    detailWithdrawalRequests: 'DetailWithdrawalRequests',
    affiliateReferrals: 'AffiliateReferrals',
}
